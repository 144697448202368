






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false
        },
        content: {
          name: "Quản lý đơn vị tiền tệ mua hàng",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true
            },
            description: {
              text: "Mô tả",
              sortable: true
            },
            exchangeRate: {
              text: "Tỷ giá",
              sortable: true,
              options: {
                filter: "number3"
              }
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime"
              }
            },
            action: {}
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name"
                    }
                  }
                }
              },
              delete: userManager.checkRole(["admin"]) && {},
              presetExchangeRate: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd"
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật tỷ giá"
                    }
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(
                          false
                        );
                      }
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật tỉ giá",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.name;
                        }
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel =
                            dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextExchangeRate: item.nextExchangeRate,
                            nextExchangeRateStartTime:
                              item.nextExchangeRateStartTime
                          });
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextExchangeRate: {
                                      type: "number",
                                      attrs: {
                                        label: "Tỷ giá mới",
                                        required: true,
                                        autofocus: true
                                      }
                                    },
                                    nextExchangeRateStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển tỉ giá",
                                        xOptions: {
                                          content: {
                                            resetable: true
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          };
                          return attrs;
                        },
                        attrs: {}
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật"
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "purchaseCurrencies",
                                    "update",
                                    {
                                      id: item._id
                                    },
                                    {
                                      data: {
                                        nextExchangeRate: data.nextExchangeRate,
                                        nextExchangeRateStartTime:
                                          data.nextExchangeRateStartTime
                                      }
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          findAllOptionsFilter(options) {
            return options;
          }
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "purchaseCurrencies",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call(
                "purchaseCurrencies",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "purchaseCurrencies",
                "update",
                {
                  id: item._id
                },
                {
                  data: item
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("purchaseCurrencies", "delete", {
                id: item._id
              });
            }
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true
                }
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả"
                }
              },
              exchangeRate: {
                attrs: {
                  label: "Tỉ giá",
                  required: true
                }
              }
            },
            insertForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: ["name", "description", "exchangeRate"]
              }
            },
            editForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: ["name", "description", "exchangeRate"]
              }
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name"
            }
          }
        }
      }
    };
  }
});
